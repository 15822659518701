import React, { Component } from 'react';

import "bootstrap/dist/css/bootstrap.css";
import Refresh from "./Refresh.jsx"
import { LoginPage } from './LoginPage.jsx';

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ticketStateRef: React.createRef(),
            ticket_type: 'unacked',
            user: null
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getTicketType = this.getTicketType.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentWillUnmount() {
        // this.handleLogout();
    }

    componentDidMount() {
        let user = localStorage.getItem('user');
        if (!user) {
            console.log("please login");
        } else {
            console.log(this.setState({ 'user': user }));
        }
    }

    handleLogout() {
        localStorage.removeItem('user');
        localStorage.removeItem('partner');
        window.location.href = "/";
    }

    getTicketType() {
        return this.state.ticket_type
    }
    handleSubmit() {
        let stateValue = this.state.ticketStateRef.current.value;
        if (stateValue === "unacked") {
            // console.log(stateValue);
            this.setState({ ticket_type: stateValue });
        } else if (stateValue === "all") {
            // console.log(stateValue);
            this.setState({ ticket_type: stateValue });
        }
        else if (stateValue === "acked") {
            // console.log(stateValue);
            this.setState({ ticket_type: stateValue });
        }
        else {
            console.log("invalid ticket type");
        }

    }
    render() {
        return (
            <React.Fragment>
                { <div>
                    <div>
                        {
                            !localStorage.getItem('user') &&
                            <button
                                className="btn btn-primary btm-sm ml-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Login"
                            >
                                <LoginPage />
                            </button>
                        }
                        {
                            localStorage.getItem('user') &&
                            <button
                                className="btn btn-primary btm-sm ml-2"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Logout"
                                style={{ float: 'right', paddingRight: '5px' }}
                                onClick={this.handleLogout}
                            >
                                Logout
                      </button>
                        }
                    </div>
                    <div>
                        {
                            localStorage.getItem('user') &&
                            <div>
                                <form action="handleSubmit">
                                    <label htmlFor="ticket_state">Case Type: </label>
                                    <select 
                                        ref={this.state.ticketStateRef} 
                                        id="ticket_type"
                                        name="ticket_type"
                                        onChange={this.handleSubmit}
                                    >
                                        <option select="selected" value=""> -- select an option -- </option>
                                        <option value="all">All</option>
                                        <option value="acked">Acknowledged</option>
                                        <option value="unacked">UnAcknowledged</option>
                                    </select>
                                </form>
                                <Refresh getTicketType={this.getTicketType} />
                            </div>
                        }
                    </div>

                </div>}


            </React.Fragment>
        );
    }
}

export default Main;