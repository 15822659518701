import React from "react"
import './modal.css';
import { httpPut } from "./helper.js"


const AckModal = ({ handleClose, show, children, details }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    const workNotesRef = React.useRef();

    const handleSubmit = () => {
        if (workNotesRef.current.value === "") {
            alert("work note is mandatory to acknowledge a case")
            return
        }
        var eventId = details.full_ticket.event_id;
        var putBody = JSON.stringify(
            {
                "events": [
                    {
                        "event_id": eventId,
                        "ticket_id": details.full_ticket.ticket_id
                    }
                ]
            }
        );
        httpPut("proxy", "ackEvents", putBody)
            .then((response) => {
                if (response.status === 200) {
                    alert("events ack'ed successfully");
                } else {
                    alert("response: " + response.statusText);
                }
                handleClose();
            })
            .catch(error => alert(error));


        // add work notes to ticket
        var workNotePutBody = JSON.stringify(
            {
                "id": details.full_ticket.ticket_id,
                "state": details.full_ticket.state,
                "work_notes": workNotesRef.current.value,
            }
        );

        httpPut("proxy", "updateTicket", workNotePutBody)
            .then((response) => {
                if (response.status === 200) {
                    alert("ticket updated successfully with work notes");
                } else {
                    alert("response: " + response.data.message);
                }
                handleClose();
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data.message);
                    // console.log("error.response.status");
                    // console.log(error.response.status);
                    // console.log("error.response.headers");
                    // console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log("error.request");
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            }

            );


    };

    return (
        <div className={showHideClassName}>
            <section >
                {children}
                <div>
                    <br />
                    <br />
                    <br />
                    <h1>Acknowledge case</h1>
                    <form>
                        <div style={{ "marginBottom": "10px" }}>
                            <label style={{ 'display': 'inline-block', 'width': '200px', 'textAlign': 'left' }} htmlFor="ticket_id">Case number:</label>
                            <input disabled="disabled" type="text" value={details.full_ticket.case_number} readOnly></input><br />
                        </div>
                        <div style={{ "marginBottom": "10px" }}>
                            <label style={{ 'display': 'inline-block', 'width': '200px', 'textAlign': 'left' }} htmlFor="work_notes">Work notes:</label>
                            <input type="text" ref={workNotesRef}></input><br />
                        </div>
                        <button type="button" value="Submit"
                            style={{ "backgroundColor": "#007bff", "color": "white" }}
                            onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
                <br />
                <button type="button"
                    style={{ "backgroundColor": "#007bff", "color": "white" }}
                    onClick={handleClose}> Close </button>
            </section>

        </div>
    );
};

export default AckModal;