import React, { Component } from 'react';
import AllTickets from './AllTickets';
import UnAckedTickets from './UnAckedTickets';
import AckedTickets from './AckedTickets';

class Refresh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showComponent: false,
            ticket_type: null
        };
        this._onButtonClick = this._onButtonClick.bind(this);
    }

    _onButtonClick() {
        console.log("Ticket type: " + this.props.getTicketType());
        this.setState({
            showComponent: true,
            ticket_type: this.props.getTicketType()
        });
    }

    render() {
        return (
            <div>
                <p>{this.props.ticket_type}</p>
                <button
                style={{ "backgroundColor": "#007bff",
                         "color": "white",
                         "marginLeft" : "100px",
                         "marginBottom" : "30px"
                       }}
                onClick={this._onButtonClick}
                >
                    Fetch cases
                    </button>
                {
                     this.state.ticket_type === 'all' ?
                      <AllTickets /> : (this.state.ticket_type === 'acked' ?
                       <AckedTickets/>: <UnAckedTickets/> )
                }
            </div>
        );
    }
}

export default Refresh;

