import React from "react"
import './modal.css';
import { httpPut } from "./helper.js"


const Modal1 = ({ handleClose, show, children, details, handleUpdateTicket }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const ticketStateRef = React.useRef();
  const ticketCommentRef = React.useRef();
  const ticketResolutionCodeRef = React.useRef();
  const ticketWorknotesRef = React.useRef();
  const ticketCauseRef = React.useRef();
  const ticketResolutionNotesRef = React.useRef();

  const handleSubmit = () => {
    var putBody = JSON.stringify(
      {
        "id": details.full_ticket.ticket_id,
        "state": ticketStateRef.current.value,
        "comment": ticketCommentRef.current.value,
        "resolution_code": ticketResolutionCodeRef.current.value,
        "resolution_notes": ticketResolutionNotesRef.current.value,
        "work_notes": ticketWorknotesRef.current.value,
        "cause": ticketCauseRef.current.value
      }
    );

    httpPut("proxy", "updateTicket", putBody)
      .then((response) => {
        if (response.status === 200) {
          alert("ticket updated successfully");
        } else {
          alert("response: " + response.data.message);
        }
        handleClose();
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          alert(error.response.data.message);
          // console.log("error.response.status");
          // console.log(error.response.status);
          // console.log("error.response.headers");
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("error.request");
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      }

      );

  };

  return (

    <div className={showHideClassName}>
      <section >
        {children}
        {/* <div className="container"> */}
        <div>
          <br/>
          <br/>
          <br/>
          <h1>Update case</h1>
          <form>
            <div style={{"marginBottom":"10px"}}>
            <label style={{'display':'inline-block', 'width': '200px', 'textAlign':'left'}} htmlFor="case_number">Case number: </label>
            <input disabled="disabled" type="text" value={details.full_ticket.case_number} readOnly></input>
            </div>
            <div style={{"marginBottom":"10px"}}>
            <label style={{'display':'inline-block', 'width': '200px', 'textAlign':'left'}} htmlFor="ticket_state">State: </label>
            <select ref={ticketStateRef} >
              <option select="selected" value=""> -- select an option -- </option>
              <option value="new">new</option>
              <option value="open">open</option>
              <option value="canceled">canceled</option>
              <option value="awaiting_info">awaiting_info</option>
              <option value="resolved">resolved</option>
            </select>
             </div>
            <div style={{"marginBottom":"10px"}}>
            <label style={{'display':'inline-block', 'width': '200px', 'textAlign':'left'}} htmlFor="ticket_comment">Comment: </label>
            <input type="text" ref={ticketCommentRef} ></input>
            </div>
            <div style={{"marginBottom":"10px"}}>
            <label style={{'display':'inline-block', 'width': '200px', 'textAlign':'left'}} htmlFor="ticket_resolution_code">Resolution code: </label>
            <select ref={ticketResolutionCodeRef} style={{'width':'175px'}}>
              <option select="selected" value=""> -- select an option -- </option>
              <option value="solved_fixed">Solved – Fixed by Support/Guidance provided</option>
              <option value="solved_prb">Solved – Fixed by closing related PRB (Problem)</option>
              <option value="solved_workaround">Solved – Workaround provided based on open PRB (Problem)</option>
              <option value="incomplete">Incomplete – requested information not provided</option>
              <option value="solved_customer">Solved by Customer</option>
              <option value="inconclusive_scope">Inconclusive – Out of scope</option>
              <option value="inconclusive_reproduce">Inconclusive – Cannot reproduce</option>
              <option value="inconclusive_noworkaround">Inconclusive – No workaround</option>
              <option value="duplicate_issue">Duplicate Issue</option>
              <option value="voided_canceled">Voided/Canceled</option>
            </select> </div>
            <div style={{"marginBottom":"10px"}}>
            <label style={{'display':'inline-block', 'width': '200px', 'textAlign':'left'}} htmlFor="ticket_resolution_notes">Resolution notes: </label>
            <input type="text" ref={ticketResolutionNotesRef} ></input><br />
            </div>
            <div style={{"marginBottom":"10px"}}>
            <label style={{'display':'inline-block', 'width': '200px', 'textAlign':'left'}} htmlFor="ticket_worknote">Work notes: </label>
            <input type="text" ref={ticketWorknotesRef}></input>
            </div>
            <div style={{"marginBottom":"10px"}}>
            <label style={{'display':'inline-block', 'width': '200px', 'textAlign':'left'}} htmlFor="ticket_cause">cause: </label>
            <input type="text" ref={ticketCauseRef}></input>
            </div>
            <button type="button" 
            style={{ "backgroundColor": "#007bff", "color": "white" }}
            value="Submit" onClick={handleSubmit}>Submit</button>
          </form>
        </div>
        <br /><br />
        <button type="button"
         style={{ "backgroundColor": "#007bff", "color": "white" }}
         onClick={handleClose}>
          Close
        </button>
      </section>

    </div>
  );
};

export default Modal1;
