import React, { Component } from "react";
// Table from react-bootstrap
import { Table } from "react-bootstrap";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import moment from "moment";
import { httpGet, getProjectId } from "./helper.js";
import { Clipboard } from "react-bootstrap-icons";
import UpdateTicket from './UpdateTicket.jsx'
import AckEvents from './AckEvents.jsx'

class UnAckedTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
    };
  }
  componentDidMount() {
    httpGet("proxy", "listTickets").then((response) => {
      // console.log("response", response);
      this.setState({
        tickets: response.data,
      });
    }).catch(function (error) {
      if (error.response) {
        alert(error.response.data.message);
      } else if (error.request) {
        console.log("error.request");
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
    }

    );
  }


  render() {
    let tickets = this.state.tickets.map((ticket, i) => {
      return (
        <React.Fragment key={ticket.ticket_id}>
          <tr>
            <td>{i + 1}</td>
            <td
              style={{ cursor: "pointer" }}
              data-toggle="collapse"
              data-target={".multi-collapse1" + i + 1}
              aria-controls={"multiCollapse" + i + 1}
            >
              {ticket.case_number}
            </td>
            <td><input disabled="disabled" type="text" value={ticket.title} readOnly></input></td>
            <td><input disabled="disabled" type="text" value={getProjectId(ticket.service_name)} readOnly></input></td>
            <td>{ticket.state}</td>
            <td className={getPriorityColor(ticket.priority)}>
              {ticket.priority}
            </td>
            <td>{formatDate(ticket.sys_created_at)}</td>
            <td>{formatDate(ticket.sys_updated_at)}</td>
          </tr>
          <tr
            className={"collapse multi-collapse1" + i + 1}
            id={"multiCollapse" + i + 1}
          >
            <td className="uk-background-muted" colSpan={6}>
              {Ticket(ticket)}
            </td>
          </tr>

        </React.Fragment>
      );
    });
    return (
      <React.Fragment>
        <h5 className="card-header">Support Cases</h5>
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Case number</th>
                <th>Short description</th>
                <th>Project ID</th>
                <th>State</th>
                <th>Priority(1-high,5-low)</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>{tickets}</tbody>
          </Table>
        </>
      </React.Fragment>
    );

    function Ticket(ticket) {
      return (
        <div ref="expanderBody" className="inner uk-grid">
          <div key={ticket.ticket_id} className="uk-width-3-4">
            <h3>
              {"Case: " + ticket.title}
              {/* {"Case: " + ticket.title} (Id: {ticket.ticket_id}) */}
              <button
                className="btn btn-primary btm-sm ml-2"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Update case"
              >
                <UpdateTicket details={{
                  ticket_id: ticket.ticket_id,
                  state: ticket.state,
                  full_ticket: ticket
                }} />
              </button>
              <button
                className="btn btn-primary btm-sm ml-2"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Acknowledge case"
              >
                <AckEvents details={{
                  ticket_id: ticket.ticket_id,
                  event_id: ticket.event_id, 
                  full_ticket: ticket
                }} />
              </button>
            </h3>
            <table key={ticket.ticket_id} className="table">
              <tbody>
                <tr>
                  <th>Case number</th>
                  <td>
                    <span id={"ticket_" + ticket.case_number}>
                      {ticket.case_number}
                    </span>
                    <button
                      name={"ticket_" + ticket.case_number}
                      className="btn btn-outline-secondary ml-4"
                      onClick={copyToClipboard}
                    >
                      <Clipboard />
                    </button>
                  </td>
                  <th>Project ID</th>
                  <td>
                    <span id={"service_" + ticket.ticket_id}>
                      {getProjectId(ticket.service_name)}
                    </span>
                    <button
                      name={"service_" + ticket.ticket_id}
                      className="btn btn-outline-secondary ml-4"
                      onClick={copyToClipboard}
                    >
                      <Clipboard />
                    </button>
                  </td>
                </tr>

                <tr>
                  <th>Priority</th>
                  <td className={getPriorityColor(ticket.priority)}>
                    {ticket.priority}
                  </td>
                  <th>State</th>
                  <td>{ticket.state}</td>
                </tr>

                <tr>
                  <th>Cause</th>
                  <td>{ticket.cause}</td>
                </tr>

                <tr>
                  <th>Created At</th>
                  <td>{formatDate(ticket.created_at)}</td>
                  {/* <th>Created By</th>
                  <td>{ticket.created_by}</td> */}
                {/* </tr>

                <tr> */}
                  <th>Updated At</th>
                  <td>{formatDate(ticket.updated_at)}</td>
                  {/* <th>Updated By</th>
                  <td>{ticket.updated_by}</td> */}
                </tr>
              </tbody>
            </table>
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className="nav-link active"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  Description
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active m-2"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                {ticket.description}
              </div>
            </div>
            {getJSONListDetails(ticket.comments, "Comments")}
            {getJSONListDetails(ticket.work_notes, "Work Notes")}
          </div>
        </div>
      );
    }

    function getJSONListDetails(data, header) {
      let headerNav = (
        <nav key={"nav" + header}>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active"
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              {header}
            </button>
          </div>
        </nav>
      );

      const listStyle = {
        height: "300px",
        padding: "10px",
        overflow: "auto",
      };

      let list;

      if (data) {
        list = data.map((val, i) => {
          return (
            <tr>
              <td>
            <div key={"list" + i} className="form-control" style={listStyle}>
              <h4>{val.created_by}</h4>
              <p style={{ fontSize: 12 }}>#{formatDate(val.created_at)}</p>
              <p style={{ borderBottom: "1px solid rgb(212, 212, 212)" }}>
                {val.value}
              </p>
            </div>
            </td>
            </tr>
          );
        });
      }

      return (
        <React.Fragment>
          {headerNav}
          <div style={{height: "200px", overflow:"auto"}}>
          <table width="100%" >
            <tbody>
          {list}
          </tbody>
          </table>
          </div>

        </React.Fragment>
      );
    }

    function formatDate(strDate) {
      if (strDate === "0001-01-01T00:00:00Z") {
        return "";
      }
      return moment(strDate).format("MMMM Do YYYY H:mm");
    }

    function getPriorityColor(priority) {
      switch (priority) {
        case "high":
          return "text-danger";
        default:
          return "";
      }
    }

    function copyToClipboard(e) {
      var copyText = document.getElementById(e.currentTarget.name);
      var textArea = document.createElement("textarea");
      textArea.value = copyText.textContent;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("Copy");
      textArea.remove();
      e.target.focus();
    }
  }
}

export default UnAckedTickets;
